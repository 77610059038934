<template>
  <FiltersWrapper>
    <CollapseBlock tablet-mode class="filters__wrapper">
      <form @submit.prevent="handleSearch">
        <div class="filters filters__form">
          <div class="filters__item">
            <CustomInput
              type="text"
              :value="filters.remont_id"
              label="Remont ID"
              @change_input="(val) => onChange('remont_id', val)"
            />
          </div>
          <div class="filters__item filters__item_select">
            <CustomSelect
              :options="this.agreementTypes"
              :value="filters.contractor_agreement_list_type_id"
              label="Тип документа"
              option-label-key="contractor_agreement_list_type_name"
              option-value-key="contractor_agreement_list_type_id"
              @change="value => this.onChange('contractor_agreement_list_type_id', value)"
            />
          </div>
          <div class="filters__item">
            <CustomSelect
              :options="this.activeOptions"
              :value="filters.is_active"
              label="Активность"
              option-label-key="label"
              :use-input="false"
              option-value-key="value"
              @change="value => this.onChange('is_active', value)"
            />
          </div>
          <div class="filters__item">
            <CustomSelect
              :options="this.signOptions"
              :value="filters.is_signed"
              label="Подпись"
              option-label-key="label"
              :use-input="false"
              option-value-key="value"
              @change="value => this.onChange('is_signed', value)"
            />
          </div>
          <q-btn
            type="submit"
            :loading="this.isFetching"
            color="primary"
            size="sm"
            padding="xs"
          >
            <q-icon name="search" />
            Поиск
          </q-btn>
        </div>
        <div class="filters">
          <div class="filters__item">
            <CustomSelect
              :options="this.errors"
              :value="filters.is_error"
              label="По ошибке"
              option-label-key="label"
              option-value-key="value"
              @change="value => this.onChange('is_error', value)"
            />
          </div>
          <FiltersWrapper class="filters__item">
            <CustomDate
              :fullWidth="true"
              placeholder="На гарантии с"
              :value="this.filters.warranty_date_begin"
              @change_date="(value) => onChange('warranty_date_begin', value)"
            />
          </FiltersWrapper>
          <FiltersWrapper class="filters__item">
            <CustomDate
              :fullWidth="true"
              placeholder="На гарантии до"
              :value="this.filters.warranty_date_end"
              @change_date="(value) => onChange('warranty_date_end', value)"
            />
          </FiltersWrapper>
        </div>
      </form>
    </CollapseBlock>
  </FiltersWrapper>
</template>

<script>
import CustomInput from "@/components/form/CustomInput";
import CustomSelect from "@/components/form/CustomSelect";
import {signOptions, activeOptions} from './services'
import {appActionTypes} from "@/services/store/modules/app_module/app_mutation_types";
import CustomDate from "@/components/form/CustomDate";
import CollapseBlock from "@/components/common/CollapseBlock";
import FiltersWrapper from "@/components/common/FiltersWrapper";

export default {
  name: "AgreementJournalFilters",
  components: {FiltersWrapper, CollapseBlock, CustomDate, CustomSelect, CustomInput},
  props: ['getData', 'isFetching', 'filters', 'onChange'],
  data() {
    return {
      signOptions, activeOptions,
      errors: [
        {value: '0', label: 'Показать все'},
        {value: '1', label: 'С ошибками'},
        {value: '2', label: 'Без ошибок'},
      ]
    }
  },
  methods: {
    handleSearch() {
      this.getData && this.getData()
    }
  },
  computed: {
    agreementTypes() {
      return this.$store.state.app.agreementTypes;
    },
  },
  mounted() {
    this.$store.dispatch(appActionTypes.getAgreementTypes)
  }
}
</script>

<style scoped lang="scss">
.filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px;
  background-color: #bcf2cf;
  border-radius: 4px;
  z-index: 10001;

  &__wrapper {
    margin-bottom: 15px;
  }

  &__form {
    margin-bottom: 5px;
    background-color: #c3f0f3;
  }

  &__item {
    width: 150px;

    &_select {
      width: 200px;
    }
  }
}
</style>
